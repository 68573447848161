body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Varela", "Maven Pro", "Didact Gothic", "Red Hat Text", "Red Hat Display",
        sans-serif;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
    min-width: 1200px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
    font-size: 25px;
    margin: 0;
    color: #8c8c8c;
    font-family: "Red Hat Display";
    font-weight: 700;
}

.ant-layout-content > header {
    min-height: 50px;
}

header h1 {
    color: rgb(21, 51, 86);
}

.header-color {
    color: rgb(21, 51, 86);
}

h2 {
    margin-top: 24px;
    clear: both;
}

.filter {
    transform: scaleY(0);
    height: 0;
    transition: all 0.3s;
}

.filter.enabled {
    transform: scaleY(1);
    transition: all 0.3s;
    height: 2em;
}

.sidebar-expand-affix .ant-affix {
    left: 10px;
    opacity: 0.5;
}

.sidebar-expand-affix .ant-affix:hover {
    left: 10px;
    opacity: .8;
}

.sidebar-expand-button {
    background-color: #f1cd15;
    visibility: visible;
}

.affix-expand-button:hover {
    opacity: 1;
}

.ant-layout-header {
    background-color: rgba(21, 51, 86);
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    justify-content: space-between;
    padding: 0 10px;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
}

.ant-layout-header .ant-menu {
    background-color: rgba(21, 51, 86);
    flex: 0 1 auto;
    width: 210px;
}

.ant-menu-submenu-placement-bottomLeft ul {
    background-color: rgba(21, 51, 86) !important;
}

.inert-menu-item:hover {
    cursor: default;
}

#pcdu-application {
    min-height: 100vh;
    width: 100% !important;
}

.nav-sidebar {
    width: auto;
}

.ant-table-wrapper {
    background-color: #fff;
    margin-bottom: 15px;
}

.App {
    text-align: center;
}

.activePage {
    color: grey !important;
}

.activePage:hover {
    cursor: default;
    text-decoration: none !important;
}

.alignCenter {
    width: 100%;
    text-align: center;
}

.alignLeft {
    text-align: left;
}

.alignRight {
    text-align: right;
}

.alignAuto {
    margin-left: auto;
}

.anchorHolder {
    background: "#fff";
    padding: 12px;
    border: 1px black solid;
    border-radius: 16px;
    width: 160px;
    margin-left: 8px;
}

.centeredContainer {
    margin: auto;
    width: fit-content;
}

.centeredText {
    text-align: center;
    width: 100%;
}

.column-selector-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 130px;
}

.detailEntryHeader {
    font-size: 18px;
    color: grey;
    margin-left: 20px;
}

.detailEntryInput {
    max-width: 300px;
    min-width: 100px;
}

.detailEntrySelect {
    margin-top: 16px;
    min-width: 200px;
}

.detailBlock {
    display: inline-block;
    width: 60%;
}

.detailButton {
    margin-left: 30%;
}

.detailInput {
    width: 60%;
    margin-left: 12px;
}

.detailProperty {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
}

.detailTitle {
    color: #404040;
    width: 30%;
    text-align: right;
    margin-right: 24px;
}

.detailValue {
    width: 60%;
    margin-left: 12px;
}

.downloadLink {
    margin-right: 16px;
    font-weight: bold;
    text-decoration: underline;
}

.down-40 {
    margin-top: 40px;
}

.floatRight {
    float: right;
}

.formHeader {
    color: grey;
    font-size: 18px;
    margin-left: 20px;
}

.formPieceTitle {
    line-height: 36px;
    text-align: right;
    margin-right: 24px;
    font-weight: bold;
}

.fullWidth {
    width: 100%;
}

.height-40 {
    height: 40px;
}

.hidden {
    display: none;
}

.iconEdit {
    width: 18px;
    color: #cccccc;
}

.iconEdit:hover {
    color: black;
    cursor: pointer;
}

.iconFilter {
    position: relative !important;
    display: inline-flex;
    vertical-align: super;
}

.inline {
    display: inline-block;
}

.inlineFlex {
    display: inline-flex;
}

.italicized {
    font-style: italic;
}

.layoutFlex {
    display: flex;
    flex: 1 0 auto;
}

.logo-text {
    font-family: "Red Hat Display";
    font-weight: 700;
}

.main-content-margin-top {
    margin-top: 15px;
}

.modalButton {
    margin: 18px;
}

.nav-item button {
    width: 100%;
}

.noDisplay {
    display: none;
}

.noPadding {
    padding: 0px;
}

.normalSider {
    width: 200px;
    background: #fff;
}

.outlinedButton {
    border: 1px black solid;
    box-shadow: 0px 1px 2px;
}

.ownLine {
    display: block;
    margin: 6px 0 6px 0;
}
.ownLineArchiveSubmission {
    display: block;
    margin: 6px 6px 6px 0;
}

.ownLineLabel {
    display: block;
    margin-top: 30px;
}

.positionRight {
    margin-left: auto;
    margin-right: 0px;
    display: block;
}

.reviewersSummary {
    margin-bottom: 18px;
}

.siderMenu,
.filter.fullHeight {
    height: 100%;
}

.smallLink {
    font-size: 15px;
    margin-left: 14px;
}

.spaceAbove-md {
    margin-top: 48px;
}

.spaceAbove-sm {
    margin-top: 24px;
}

.spaceAbove-xs {
    margin-top: 16px;
}

.buttonLine {
    margin: 0 20px 10px 0;
}

.spaceBelow-xs {
    margin-bottom: 16px;
}

.spaceBelow-sm {
    margin-bottom: 24px;
}

.spaceBelow-md {
    margin-bottom: 48px;
}

.spaceBetween-md {
    margin-left: 20px;
    margin-right: 20px;
}

.spaceBetween-sm {
    margin-left: 12px;
    margin-right: 12px;
}

.spaceBetween-xs {
    margin-left: 4px;
    margin-right: 4px;
}

.successMessage {
    margin-top: 6px;
    color: green;
    font-style: italic;
}

.errorMessage {
    margin-top: 6px;
    color: red;
    font-style: italic;
}

.tabbed {
    margin-left: 20px;
}

.warning {
    color: red;
    font-style: italic;
    margin-bottom: 18px;
    text-align: center;
}

/* ag grid stuff */

.currentCell {
    background: #feffed;
}

.currentHeader {
    background: #fbffcc;
}

.dateCell {
    background: #f1f3f1;
}

.dateHeader {
    background: #e3e8e3;
}

.primaryCell {
    background: #f2f5ff;
}

.primaryHeader {
    background: #dfe8f7;
}

.rightCell {
    border-right: 2px black solid !important;
}

/* Modal Content */

.my-modal {
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.2);
    /* Black w/ opacity */
}

.my-modal-header {
    padding: 4px 20px;
    border: none;
}

.my-modal-body {
    padding: 2px 16px;
}

.my-modal-button {
    width: auto;
    height: auto;
    border-radius: 8px;
    font-size: 28px;
    margin: 10px;
}

.clear-modal-content {
    background-color: initial;
    margin: auto;
    padding: 20px;
    max-width: 40%;
    max-height: 60%;
    width: auto;
    height: auto;
}

.visible-modal-content {
    background-color: #ffffff !important;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    min-width: 30%;
    min-height: 20%;
    max-width: 60%;
    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.my-modal-img {
    max-height: 50%;
    height: auto;
    max-width: 75%;
    width: auto;
    margin-top: -15%;
    display: inline-block;
}

/* Ant */

.ant-anchor-wrapper {
    margin: 0;
    padding-left: 20px;
}

.ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
    border-radius: 0 !important;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection {
    background-color: #e4e6e6;
}

.ant-select-selection:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
}

.st0 {
    fill: #f1cd15;
}

.st1 {
    fill: #5fb3e4;
}

.st2 {
    fill: #0071b9;
}

.st3 {
    fill: #008345;
}

.st4 {
    fill: #c4beb6;
}

.st5 {
    fill: #e02826;
}

.st6 {
    fill: #f1e4b2;
}

.st7 {
    fill: #ffffff !important;
}

.st8 {
    fill: #231f20;
}

.st9 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #231f20;
}

.st10 {
    fill-rule: evenodd;
    clip-rule: evenodd;
}

.st11 {
    fill: none;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.logo-menu-item {
    background-color: transparent;
}

.ant-cascader-_placeholder {
    color: black;
}

.input {
    position: relative;
    z-index: 1;
    display: block;
    vertical-align: top;
    overflow: hidden;
    font-size: 20px;
}

.editable__input {
    font-size: 14px;
}

.header__field--sae {
    background: transparent;
    width: 100%;
    color: #333;
    font-weight: 500;
}

.header__field--tmp {
    background: transparent;
    width: 100%;
    color: #333;
    font-weight: 500;
    margin-bottom: 5px;
}

.header__label--sae {
    width: 100%;
    text-align: left;
    color: #7771ab;
    padding: 14px 0 0;
    font-size: 15px;
    font-weight: 600;
}

.input__field {
    position: relative;
    display: block;
    float: left;
    padding: 0.8em 0.8em 0.8em 0;
    width: 60%;
    border: none;
    border-radius: 0;
    background: #f0f0f0;
    font-weight: 400;
    -webkit-appearance: none;
}

.select-dropdown .ant-select-selector {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.input__field:focus {
    outline: none;
}

.input__label {
    display: inline-block;
    width: 40%;
    color: #696969;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input__label-feedback {
    display: inline-block;
    width: 40%;
    color: #696969;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #7771ab;
    font-weight: 500px;
}

.input__label-content {
    position: relative;
    display: block;
    padding: 1.6em 0;
    width: 100%;
    font-family: "Varela";
}

/* Sae */

.readonly-input--sae {
    transition: background-color 0.3s, padding 0.3s;
    padding: 0 0 0 0;
    margin-bottom: 5px;
}

.input--sae {
    transition: background-color 0.3s, padding 0.3s;
    padding: 0 0 0 0;
    margin-bottom: 5px;
}

.input--sae:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
}

.input__field--sae {
    background: transparent;
    width: 100%;
    margin: 5px 10px 0px 0px;
    font-weight: 500;
}

.input__field--saeTmp {
    background: transparent;
    width: 100%;
    color: #333;
    font-weight: 500;
}

.input__label--sae {
    position: absolute;
    min-width: 100%;
    width: max-content;
    text-align: left;
    color: #7771ab;
    padding: 0px;
    height: 100%;
    pointer-events: none;
    display: flow-root;
}

.icon--sae {
    position: absolute;
    bottom: 8px;
    font-size: 1em;
    opacity: 0.5;
    left: -30px;
    color: #333;
    pointer-events: none;
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
}

.input__label-content--sae {
    padding: 0;
    font-size: 22px;
    font-weight: 600;
    display: inline-block;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
}

.input__label-content--sae-feedback {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: #7771ab;
    display: inline-block;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
}

.input__field--sae:focus + .input__label--sae .input__label-content--sae,
.input--filled .input__label-content--sae {
    -webkit-transform: translate3d(0, -4px, 0) scale3d(0.7, 0.7, 1);
    transform: translate3d(0, -4px, 0) scale3d(0.6, 0.6, 1);
}

.input__field--sae:focus + .input__label--sae .icon--sae,
.input--filled .icon--sae {
    -webkit-transition-delay: 0.01s;
    transition-delay: 0.01s;
    -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, -90deg);
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, -90deg);
}

.input__field--datepicker {
    width: 35%;
    min-width: 160px;
    padding: 5px 11px;
    margin-top: calc(5px + 0.8em);
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.selectable-date-range-label {
    color: #7771ab;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
}

.sidebar-container:first-child li:first-child {
    margin-top: 0px !important;
}

#root {
    height: 100vh;
}

.ant-select-arrow {
    margin-right: 4px;
}

.auth-box-shadow {
    box-shadow: 0px 0px 5px 6px #f0f0f0;
    background-color: #001529;
    border-radius: 10px;
}

.break-word {
    word-break: break-word !important;
}

/* Section background color (light gray) :: rgba(240, 242, 245) */
.ant-table-title {
    background-color: rgba(240, 242, 245);
}

.bordered-scroll-table table {
    border-bottom: 2px solid rgb(21, 51, 86);
}

table {
    min-height: 120px;
}

.no-bottom-border table {
    border-bottom: none;
}

th {
    background-color: rgb(21, 51, 86) !important;
    color: #ffffff !important;
}

.bordered-scroll-table td {
    border-bottom: none !important;
}

.ant-table-selection-column,
.ant-table-row-expand-icon-cell {
    width: 30px !important;
}

.bordered-scroll-table tr.ant-table-row td::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fafafa;
}

.bordered-scroll-table tr.ant-table-row-selected td::after {
    content: none;
}

td {
    max-width: 500px;
}

.bordered-scroll-table tr.ant-table-measure-row td::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(21, 51, 86);
}

.bordered-scroll-table .first-column {
    border-left: 2px solid  rgb(21, 51, 86);
    border-bottom: none !important;
}

.table-selection-border .ant-table-selection-column {
    border-left: 2px solid rgb(21, 51, 86);
    border-bottom: none !important;
}

.table-expansion-border .ant-table-row-expand-icon-cell {
    border-left: 2px solid rgb(21, 51, 86);
    border-bottom: none !important;
}

.bordered-scroll-table .last-column {
    border-right: 2px solid  rgb(21, 51, 86);
    border-bottom: none !important;
}

.bordered-scroll-table .ant-table-expanded-row {
    border-left: 2px solid rgb(21, 51, 86);
    border-right: 2px solid  rgb(21, 51, 86);
}

.audit-table-container table {
    border-bottom: none !important;
}

.ant-layout-sider-trigger {
    position: sticky;
    height: fit-content;
    margin-bottom: 0px;
    bottom: 20px;
}

.ant-layout-sider-has-trigger {
    padding-bottom: 65px;
}

.year-category-dropdown-container {
    display: flex;
    padding: 15px 0px 0px 0px;
    max-width: 600px;
}

.year-dropdown {
    width: 180px;
}

.category-dropdown {
    width: 400px;
}

.bordered-scroll-table .ant-table-tbody > tr.ant-table-row-selected > td {
    border-color: initial;
}

.ant-btn {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.ant-table-wrapper, .ant-picker-calendar {
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
}
.calendar-selector .ant-picker-calendar-header {
    padding-right: 10px;
}

.table-button-bar {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.reduced-padding-sidebar li {
    padding-right: 0px !important;
}


/* lighter color for better contrast on active filters, sorts for tables */
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    color: #ffffff;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.ant-table-filter-trigger .fa-magnifying-glass-plus,
.ant-table-filter-trigger .fa-filter-circle-xmark,
.ant-table-filter-trigger .fa-calendar-xmark,
.ant-dropdown-trigger.ant-table-filter-trigger:hover {
    color: #f1cd15 !important;    
}

/* box shadows on table pagination */
ul.ant-pagination li {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.ant-table-wrapper ul.ant-pagination li {
    box-shadow: none;
}

/* placeholder element when table is empty */
div.ant-empty.ant-empty-normal {
    margin: 0;
}

.ant-table-placeholder {
    height: 160px;
}

.no-table-title .ant-table-title {
    display: none;
}

.large-column {
    min-width: 450px;
}

.medium-column {
    min-width: 225px;
}

/* make sure the disabled input fields still have sufficient contrast */
.ant-select-disabled .ant-select-selector {
    background: #ffffff !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.ant-picker-disabled, 
.ant-input-number-disabled {
    background: #ffffff !important;
}

.ant-picker-disabled input, 
.ant-input-number-disabled input {
    background: #ffffff !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

/* collapse styles for export tables component */
.export-tables-container .ant-collapse {
    box-shadow:2px 2px 5px #d8d8d8;
}

.export-tables-container .ant-collapse:hover {
    box-shadow:2px 2px 5px #949494;
}

.export-tables-container .ant-collapse-item {
    min-height: 45px;
}

.export-tables-container .ant-collapse-item .ant-collapse-item-active {
    border: 1px solid rgba(21, 51, 86);
}

.export-tables-container .ant-collapse-item .ant-collapse-header {
    font-size: 14px;
    font-weight: 600;
}

.export-tables-container .ant-collapse-item:hover:not(.ant-collapse-item-active) > .ant-collapse-header {
    color: #1890ff;
}

.export-tables-container .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
    background-color: rgba(21, 51, 86);
    color: #ffffff;
}

.ant-checkbox-inner{
    border-radius: 0 !important;
}

.ant-picker {
    border-radius: 0 !important;
}

.ant-picker-dropdown .ant-picker-panel-container{
    border-radius: 0 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border-radius: 0 !important;
}

.ant-picker-cell-inner{
    border-radius: 0 !important;
}

.ant-picker-cell .ant-picker-cell-range-start .ant-picker-cell-range-end .ant-picker-cell-in-view .ant-picker-cell-today{
    border-radius: 0 !important;
}

.ant-collapse {
    border-radius: 0 !important;
}

.export-tables-container .ant-collapse-item.ant-collapse-item-active > .ant-collapse-extra {
    color: #ffffff !important;
}

.export-table-form-container .ant-picker {
    width: 100%;
}

.export-table-form-container .ant-form-item-label {
    padding: 0
}

.export-table-form-container .ant-form-item-label label {
    color: #7771ab !important;
    font-weight: bold;
}

.ant-form-item-label > label {
    color: #7771ab !important;
    font-weight: bold;
}

.export-table-form-container .ant-form-item {
    padding: 10px;
    margin-bottom: 10px;
}

.export-table-form-container .ant-form-item:hover{
    background-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
}

.export-processing {
    color: #f1cd15;
    font-weight: 600;
}

.export-processing .ant-spin-dot-item {
    background-color: #f1cd15;
}

.submission-search {
    width: 272px;
    margin-bottom: 8px;
    display: block;
    height: 32px;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
}

.submission-search:focus {
    border-color: #40a9ff;
}

.text-white-space {
    white-space: pre-line;
}

.ant-picker-time-panel {
    display: none;
}

.default-pointer {
    cursor: default;
}

.duedate-modal {
    color: red;
    font-size: 16px;
}

.duedate-border {
    padding-bottom: 15px;
    font-weight: 600;
    text-align: center;
}

.due-Date-Modal .ant-modal-header{
    background-color: #153356;
    border-radius: 0;
    padding: 4px 0;
}

.due-Date-Modal .ant-modal-header .ant-modal-title {
    color: #ffffff;
    font-weight: 600;
    margin-left: 2px;
}

.checkbox-column-fit-content-label label {
    width: fit-content;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 8px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.category-add-options {
    margin-left: 20px;
}

.ant-form-vertical .ant-form-item-label > label {
    color: #7771ab;
    font-size: 13px;
    font-weight: 600;
}

.ant-form-item-label {
    padding: 0px !important;
}

.asterisk {
    color: red;
    font-size: 16px;
}

/* better contrast for disabled radio button label and input text */
.high-contrast-radio-form .ant-input-disabled {
    color: rgb(0, 0, 0, 0.5);
}

.high-contrast-radio-form .ant-input[disabled] {
    color: rgb(0, 0, 0, 0.5);
}

.high-contrast-radio-form .ant-radio-disabled  + span {
    color: rgb(0, 0, 0, 0.5);
}

.high-contrast-radio-form .ant-radio-checked  + span {
    color: rgb(0, 0, 0, 1);
}

.ant-select-single {
    height: auto ;
}

.ant-table-wrapper .ant-table-container thead tr:first-child th:last-child {
    border-start-end-radius: 0px;
}

.ant-table-wrapper .ant-table-container thead tr:first-child th:first-child {
    border-start-start-radius: 0px; 
}

.ant-table-wrapper .ant-table-filter-trigger {
    color: #bfbfbf;
}

.ant-btn {
 border-radius: 0px;
}

.ant-radio-button-wrapper:first-child {
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
}
.ant-radio-button-wrapper:last-child {
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
}
.ant-input {
    border-radius: 0px;
}

.ant-select-single .ant-select-selector {
    border-radius: 0px;
}

.ant-select-multiple .ant-select-selector {
    border-radius: 0px;
}

.link-button {
    height: 32px;
    padding: 4px 15px;
    color: #fff;
    background: #1677ff;
}

.link-button:hover {
    color:  #fff;
    background-color: #0958d9;
}

.ant-divider-horizontal {
    margin: 0px;
}

.ant-btn-danger[disabled] {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, .25);
    text-shadow: none;
}

.ant-btn-danger {
    background: #ff4d4f;
    border-color: #ff4d4f;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
}

.ant-menu-title-content {
    font-family: "Varela", "Maven Pro", "Didact Gothic", "Red Hat Text", "Red Hat Display",
    sans-serif;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-qnu6hi).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.ant-menu-inline .ant-menu-item {
    width: calc(100% - 4px);
}

.ant-menu-light .ant-menu-item-selected {
    border-radius: 0px;
}
